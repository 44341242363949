<!--
 * @Author: guodm guodm@9856
 * @Date: 2023-08-10 09:21:26
 * @LastEditors: guodm guodm@9856
 * @LastEditTime: 2023-08-22 15:49:50
 * @FilePath: \website\src\pages\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="headerCon" ref="heander">
        <headerPage @change="scrollToAnchor" />
    </div>
    <div ref="sectionRef"></div>
    <div ref="section1Ref">
        <banner @change="scrollToAnchor"/>
    </div>
    <div ref="section2Ref">
        <vision/>
    </div>
    <div ref="section3Ref">
        <swiperApp/>
    </div>
    <div ref="section4Ref">
        <knowledge/>
    </div>
    <div ref="section6Ref">
        <tool/>
    </div>
    <div ref="section5Ref">
        <footnote />
    </div>
    <div class="downBtn downclick" v-if="store.isApp">
        <img src="@/assets/down_btn.png" alt="">
    </div>
    <div id="iphoneid" class="iphonetxt">
		目前只支持安卓系统
	</div>
</template>
<script setup>
import { ref,onMounted,reactive,nextTick } from 'vue';
import headerPage from "./components/header"
import banner from "./components/banner"
import swiperApp from "./components/swiper-app"
import vision from "./components/vision"
import knowledge from "./components/knowledge"
import tool from "./components/tool"
import footnote from "./components/footnote"
import WOW from 'wow.js'
import { mainStore } from "@/sheep/store"
import { getUrlKey } from "@/sheep/helper/utils"
const store = mainStore()
const heander = ref(null);
const sectionRef = ref(null);
const section1Ref = ref(null);
const section2Ref = ref(null);
const section3Ref = ref(null);
const section4Ref = ref(null);
const section5Ref = ref(null);
const section6Ref = ref(null);
const pageData = reactive({
    height:0,
    showStand: false,
    timestand:true,
    shStand:true,
    invite_code:''
})
onMounted(() => {
    pageData.invite_code = getUrlKey('invite_code');
    wowjsFn()
    store.isAppFun();
    downLoadFun();
    
});
function downLoadFun(){
    const s = document.createElement('script');
		s.type = 'text/javascript';
		s.src = '//web.cdn.openinstall.io/openinstall.js';
		s.addEventListener('load', () => {
			var data = OpenInstall.parseUrlParams();
			new OpenInstall({
				appKey: "uaff7j", //appkey参数配置,需要自行替换对应的appkey
				preferWakeup:true,
				onready: function() {
					var m = this,
					button = document.getElementsByClassName("downclick");
					var u = navigator.userAgent;
					var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
					var iphoneid = document.getElementById("iphoneid")
					m.schemeWakeup();
					if(store.isApp){
                        for (let index = 0; index < button.length; index++) {
                            const element = button[index];
                            element.onclick = function() {
                                if(isAndroid){
                                    m.wakeupOrInstall();
                                    return false;
                                }else{
                                    if(pageData.timestand){
                                        pageData.timestand = false
                                        iphoneid.style.display = "block"
                                        var clicktime = setTimeout(()=>{
                                            iphoneid.style.display = "none"
                                            pageData.timestand = true
                                        },2000)
                                    }
                                    
                                }
                            }
                        }
						
					}
					
				}
			}, data);
		}, false);
		document.head.appendChild(s);
}
const wowjsFn = () =>{
    let wow = new WOW({
        boxClass: "wow", // animated element css class (default is wow)
        animateClass: "animated", // animation css class (default is animated)
        offset: 100, // distance to the element when triggering the animation (default is 0)
        mobile: true, // trigger animations on mobile devices (default is true)
        live: true, // act on asynchronously loaded content (default is true)
        callback: function (box) {
            // the callback is fired every time an animation is started
            // the argument that is passed in is the DOM node being animated
        },
        scrollContainer: null, // optional scroll container selector, otherwise use window
        resetAnimation: true,
    });
    wow.init()
}

// 锚点

function scrollToAnchor(refDom,options = {}){
    let newRef = eval(refDom);
    if (newRef.value) {
        scrollIntoView(newRef.value,options)
    }
}
/**
 * 
 * @param {*} elem 滚动的元素
 * @param {*} options 滚动配置
 */
function scrollIntoView(elem, options) {
    options = {
        ...options,
        duration: options.duration || 1000,
        offset: heander.value.clientHeight,
        ease: 'ease-out'
    }
 
    var scrollOffset = elem.getBoundingClientRect().top - options.offset,
        totalScrollOffset = window.scrollY + scrollOffset,
        duration = options.duration,
        startTime = Date.now(),
        distance,
        currentScrollPosition;
 
    requestAnimationFrame(function anim() {
        currentScrollPosition = window.scrollY;
        distance = totalScrollOffset - currentScrollPosition;
 
        var elapsed = Date.now() - startTime;
        var progress = Math.min(1, elapsed / duration);
 
        window.scrollTo(0, currentScrollPosition + (distance * progress));
 
        if (progress < 1 && distance !== 0) {
            requestAnimationFrame(anim);
        }
    });
}


</script>

<style scoped lang="scss">
.downBtn{
    position: fixed;
    bottom: 80px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
    img{
            width: 70%;
            animation: breathe 2s ease-in-out infinite;
        }
    }
    
/* 定义呼吸动画 */
@keyframes breathe {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.iphonetxt{
    position: fixed;
    top: 50%;
    left: 28%;
    width: 24rem;
    height: 4rem;
    text-align: center;
    line-height: 4rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 2rem;
    color: #fff;
    display: none;
    z-index: 99;
}
.headerCon{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10;
   
} 
.container-dom{
    // position: fixed;
    // width: 100%;
    // height: calc( 100vh - v-bind('pageData.height') );
    // left: 0;
    // top: v-bind('pageData.height');
    // z-index: 10;
    // overflow-y: auto;
    // background: rgba(246, 246, 246, 1);
}
</style>
  