<template>
    <div class="visionHome d-flex justify-content-center">
        <div class="vision  col-sm-11 col-xs-12 col-xl-8 d-flex justify-content-center flex-column">
            <div class="title-con">健康资讯</div>
            <div class="lists no-gutters d-flex justify-content-between">
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0s">
                    <div class="content">
                        <div class="title">
                            <img src="@/assets/brand.png" alt="" srcset="">
                            <div class="title_val">资讯平台</div>
                        </div>
                        
                        <div class="text">在现代忙碌的生活中，健康是每个人最宝贵的财富。我们深知您对健康的渴望与追求，因此倾力打造了一款全面、及时的健康资讯平台。</div>
                    </div>
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.1s">
                    <div class="content">
                            <div class="title">
                            <img src="@/assets/home.png" alt="" srcset="">
                            <div class="title_val">前沿可靠</div>
                        </div>
                        
                        <div class="text">在这里，您可以轻松获取到最新的养生健康信息，了解行业动态，掌握前沿养生健康信息。我们拥有专业的健康团队，实时追踪最新的养生健康内容，确保您获取的每一份内容都是权威、可靠的</div>
                    </div>
                    
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
                    <div class="content">
                            <div class="title">
                            <img src="@/assets/future.png" alt="" srcset="">
                            <div class="title_val">视频/文章</div>
                        </div>
                        
                        <div class="text">我们还为您提供丰富的视频内容，让您在忙碌之余，通过观看视频的方式，轻松学习保健养生知识。从日常饮食到运动锻炼，从疾病预防到心理健康，我们应有尽有，让您的健康之路更加顺畅。</div>
                    </div>
                        
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.visionHome{
    overflow: hidden;
    background: white;
    .vision{
        padding: 8rem 0;
        .title-con{
            font-size: 3.6rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            text-align: left;
            margin-bottom: 7rem;
        }
        .lists{
            .list:nth-child(2){
                margin: 0 3rem;
            }
            .list{
                width:33.33%;
                transform: translate3d(0, 0, 0);
                transition: all .4s;
                .content{
                    border-radius: 20px;
                    border: 1px solid #F2F2F2;
                    width: 100%;
                    height: 100%;
                    padding: 1.5625vw 2.2396vw;
                    span{
                        font-size: 2.4rem;
                    }
                }
                .title{
                    font-size: 3.2rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    letter-spacing: 0px;
                    
                    img{
                        width: 8.4rem;
                        height: 8.4rem;
                        margin-bottom: 2rem;
                    }
                }
                .title_val{
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 2.4rem;
                    color: #222222;
                    margin-bottom: 1.3rem;
                }
                .text{
                    font-size: $align-16;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #A7A9A9;
                    line-height: 1.5;
                }
            }
            .list:hover{
                transform: translate3d(0, -10px, 10px);
                transition: all .4s;
            }
           
        }
        
    }
}
@media screen and (max-width: 767px) {
    .visionHome{
        background: inherit;
        .vision{
            padding: $align-16!important;
            background: white;
            margin-top: 2rem;
            border-radius: $border-radius-con;
            margin: 6px 8px 0 8px;
            .title-con{
                font-size: $font-size-26;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
                color: #333333;
                text-align: left;
                margin-bottom: 15px;
                margin-top: 10px;
            }
            .lists{
                flex-wrap: wrap;
                .list{
                    margin:0!important;
                    width: 100%!important;
                    margin-top: $align-16!important;
                    transform: inherit;
                    .title{
                        justify-content: flex-start!important;
                        margin-bottom: 8px;
                        align-items: center;
                        
                        img{
                            width: 2.3rem;
                            height: 2.3rem;
                            margin-right: 1rem;
                            margin-bottom: 0;
                        }
                        .title_val{
                            margin-bottom: 0;
                            font-weight: bold;
                            font-size: 14px;
                            color: #222222;
                        }
                    }
                    .content{
                        padding: 0;
                        box-shadow:inherit;
                        border: 0;
                        .title{
                            display:flex;
                        }
                        .text{
                            margin-left: 0;
                        }
                        span{
                            font-size: $font-size-22;
                        }
                    }
                }
                .list:hover{
                    transform: inherit;
                }
            }
        }
    }
   
}
</style>