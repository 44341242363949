<template>
    <div class="visionHome d-flex justify-content-center">
        <div class="vision col-sm-11 col-xs-12 col-xl-8 d-flex justify-content-center flex-column">
            <div class="title-con">健康工具</div>
            <div class="lists no-gutters d-flex justify-content-between">
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0s">
                    <div class="content">
                        <div class="title">
                            <img src="@/assets/tool1.png" alt="" srcset="">
                        </div>
                        <div class="title_val">血压检测</div>
                        <div class="text">实时监测，守护血压健康。</div>
                    </div>
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.1s">
                    <div class="content">
                        <div class="title">
                            <img src="@/assets/tool2.png" alt="" srcset="">
                        </div>
                        <div class="title_val">血糖检测</div>
                        <div class="text">精准监测，掌控血糖平衡。</div>
                    </div>
                    
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
                    <div class="content">
                            <div class="title">
                            <img src="@/assets/tool3.png" alt="" srcset="">
                        </div>
                        <div class="title_val">体重管理</div>
                        <div class="text">科学减重，塑造理想身材。</div>
                    </div>
                        
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0s">
                    <div class="content">
                        <div class="title">
                            <img src="@/assets/tool4.png" alt="" srcset="">
                        </div>
                        <div class="title_val">规划饮食</div>
                        <div class="text">智能规划，健康饮食每一天。</div>
                    </div>
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.1s">
                    <div class="content">
                            <div class="title">
                            <img src="@/assets/tool5.png" alt="" srcset="">
                        </div>
                        <div class="title_val">健康食谱计划</div>
                        <div class="text">定制食谱，健康美味两不误。</div>
                    </div>
                    
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
                    <div class="content">
                            <div class="title">
                            <img src="@/assets/tool6.png" alt="" srcset="">
                        </div>
                        <div class="title_val">健康计算器</div>
                        <div class="text">一键计算，健康数据尽在掌握。</div>
                    </div>
                        
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.visionHome{
    overflow: hidden;
    background: url(@/assets/swiper_bg.png) no-repeat;
    background-size:100% 100%;
    .vision{
        padding: 8rem 0;
        .title-con{
            font-size: 3.6rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            text-align: left;
            margin-bottom: 7rem;
        }
        .lists{
            margin-bottom: 4rem;
                flex-wrap: wrap;
        }
        .lists{
           
            .list{
                width:calc(( 100% - 4rem) / 3 );
                aspect-ratio: 1 / 1;
                transform: translate3d(0, 0, 0);
                transition: all .4s;
                position: relative; 
                margin-bottom: 6rem;
                .content{
                    width: 100%;
                    height: 100%;
                    padding: 1.5625vw 2.2396vw;
                    span{
                        font-size: 2.4rem;
                    }
                    
                }
                
                .title{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    z-index: -1;
                    font-size: 3.2rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    letter-spacing: 0px;
                    margin-bottom: 2rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .title_val{
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 2.4rem;
                    color: #222222;
                    margin-bottom: 1.3rem;
                }
                .text{
                    font-size: $align-16;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #A7A9A9;
                    line-height: 1.5;
                }
            }
            .list:hover{
                transform: translate3d(0, -10px, 10px);
                transition: all .4s;
            }
           
        }
        .lists2{
            .list{
                .content{
                    border-bottom: 0;
                }
            }
        }
        
    }
}
@media screen and (max-width: 767px) {
    .visionHome{
        background: inherit;
        .vision{
            padding: $align-16!important;
            background: white;
            margin: 6px 8px 0 8px;
            border-radius: $border-radius-con;
            
            .title-con{
                font-size: $font-size-26;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
                color: #333333;
                text-align: left;
                margin-bottom: 0;
                margin-top: 10px;
            }
            .lists{
                flex-wrap: wrap;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0;
                margin-top: 20px;
                .content{
                    padding: 0!important;
                }
                .list{
                    width: calc( 50% - 4px );
                    margin: 0!important;
                    margin-bottom: 8px!important;
                    padding: 16px 13px;
                }
                .list:hover{
                    transform: translate3d(0, 0, 0);
                    transition: all .4s;
                }
            }
        }
    }
   
}
</style>