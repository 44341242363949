<template>
    <div class="visionHome d-flex justify-content-center">
        <div class="vision col-sm-11 col-xs-12 col-xl-8 d-flex justify-content-center flex-column">
            <div class="title-con">健康知识</div>
            <div class="lists no-gutters d-flex justify-content-between">
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0s">
                    <div class="content">
                        <div class="list_con">
                            <div class="title">
                                <img src="@/assets/knowledge1.png" alt="" srcset="">
                            </div>
                            <div class="title_val">食物百科</div>
                            <div class="text">涵盖了400+食物，详尽解析功效、宜忌、搭配与营养，配以多种种图文介绍，可按营养、功效、性味归经快速分类查询。</div>
                        </div>
                    </div>
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.1s">
                    <div class="content">
                        <div class="list_con">
                            <div class="title">
                                <img src="@/assets/knowledge2.png" alt="" srcset="">
                            </div>
                            <div class="title_val">养生药膳</div>
                            <div class="text">精选多种日常食物药膳，旨在通过日常饮食调理身体各方面，按功效分类，助您轻松实现健康饮食。</div>
                        </div>
                            
                    </div>
                    
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
                    <div class="content">
                        <div class="list_con">
                            <div class="title">
                                <img src="@/assets/knowledge3.png" alt="" srcset="">
                            </div>
                            <div class="title_val">养生茶</div>
                            <div class="text">精选了众多以中草药为主的日常茶饮小方，旨在辅助调治各种症状，为您提供自然健康的解决方案。</div>
                        </div>
                            
                    </div>
                        
                </div>
            </div>
            <div class="lists lists2 no-gutters d-flex justify-content-between">
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0s">
                    <div class="content">
                        <div class="list_con">
                            <div class="title">
                                <img src="@/assets/knowledge4.png" alt="" srcset="">
                            </div>
                            <div class="title_val">中草药</div>
                            <div class="text">精心收录了多种中草药的功效、详细的使用方法及关键注意事项，并辅以1100张相关形态品类的精美图片介绍，为您呈现一个全面而直观的中草药世界。</div>
                        </div>
                        
                    </div>
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.1s">
                    <div class="content">
                        <div class="list_con">
                            <div class="title">
                                <img src="@/assets/knowledge5.png" alt="" srcset="">
                            </div>
                            <div class="title_val">慢病指导</div>
                            <div class="text">针对高血压、糖尿病和冠心病患者，提供了详尽的生活、饮食和运动指导，帮助患者建立健康的生活方式，有效管理疾病。</div>
                        </div>
                    </div>
                    
                </div>
                <div class="list wow fadeInUp cursor-pointer" data-wow-duration="0.4s" data-wow-delay="0.2s">
                    <div class="content">
                        <div class="list_con">
                            <div class="title">
                                <img src="@/assets/knowledge6.png" alt="" srcset="">
                            </div>
                            <div class="title_val">急救知识</div>
                            <div class="text">收录了关于常见猝死、心梗、突发血管疾病、外伤以及儿童急救的紧急处理方法，为您提供全面的急救指南。</div>
                        </div>
                            
                    </div>
                        
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive} from 'vue';
const pageData = reactive({
    lists:[
        {
            title:"食物百科",
            desc:"涵盖了400+食物，详尽解析功效、宜忌、搭配与营养，配以多种种图文介绍，可按营养、功效、性味归经快速分类查询。"
        },{
            title:"养生药膳",
            desc:"精选多种日常食物药膳，旨在通过日常饮食调理身体各方面，按功效分类，助您轻松实现健康饮食。"
        },{
            title:"养生茶",
            desc:"精选了众多以中草药为主的日常茶饮小方，旨在辅助调治各种症状，为您提供自然健康的解决方案。"
        },{
            title:"中草药",
            desc:"精心收录了多种中草药的功效、详细的使用方法及关键注意事项，并辅以1100张相关形态品类的精美图片介绍，为您呈现一个全面而直观的中草药世界。"
        },{
            title:"慢病指导",
            desc:"针对高血压、糖尿病和冠心病患者，提供了详尽的生活、饮食和运动指导，帮助患者建立健康的生活方式，有效管理疾病。"
        },{
            title:"急救知识",
            desc:"收录了关于常见猝死、心梗、突发血管疾病、外伤以及儿童急救的紧急处理方法，为您提供全面的急救指南。"
        }
    ]
})
</script>

<style lang="scss" scoped>
.visionHome{
    overflow: hidden;
    background: #F8FAFB;
    .vision{
        padding: 8rem 0;
        .title-con{
            font-size: 3.6rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            text-align: left;
            margin-bottom: 7rem;
        }
        .lists{
            .list{
                width:33.33%;
                
                .list_con{
                    transform: translate3d(0, 0, 0);
                    transition: all .4s;
                }
                .content{
                    border: 1px solid #F2F2F2;
                    width: 100%;
                    height: 100%;
                    padding: 1.5625vw 2.2396vw;
                    border-left: 0;
                    border-top: 0;
                    span{
                        font-size: 2.4rem;
                    }
                    
                }
                
                
                .title{
                    font-size: 3.2rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    letter-spacing: 0px;
                    margin-bottom: 2rem;
                    img{
                        width: 8.4rem;
                        height: 8.4rem;
                    }
                }
                .title_val{
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 2.4rem;
                    color: #222222;
                    margin-bottom: 1.3rem;
                }
                .text{
                    font-size: $align-16;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #A7A9A9;
                    line-height: 1.5;
                }
            }
            // .list:first-child{
            //     .content{
            //         padding-left:0;
            //     }
                
            // }
            .list:last-child {
                .content{
                    border-right: 0; 
                }
                
            }
            .list_con:hover{
                transform: translate3d(0, -10px, 10px);
                transition: all .4s;
            }
           
        }
        .lists2{
            .list{
                .content{
                    border-bottom: 0;
                }
            }
        }
        
    }
}
@media screen and (max-width: 767px) {
    .visionHome{
        background: inherit;
        .vision{
            padding: $align-16!important;
            background: white;
            margin: 6px 8px 0 8px;
            border-radius: $border-radius-con;
            
            .title-con{
                font-size: $font-size-26;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
                color: #333333;
                text-align: left;
                margin-bottom: 20px;
                margin-top: 10px;
            }
            .lists{
                
                .list{
                    text-align: center;
                    
                    .content{
                        padding: 18px 0;
                    }
                    .title{
                        display: flex;
                        justify-content: center;
                        img{
                            width: 40px;
                            height: 40px;
                        }
                        
                    }
                    .title_val{
                            margin-bottom: 0;
                            font-weight: bold;
                            font-size: 14px;
                            color: #222222;
                    }
                    .text{
                        display: none;
                    }
                }
                
            }
        }
    }
   
}
</style>